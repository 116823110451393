<template>
  <main>
    <div class="chip">Prihaja kmalu</div>
    <div class="coloredTitle">
      Najboljša slovenska tržnica<br />
      za umetnike in prodajalce<br />
      digitalnih predmetov
    </div>
    <p class="subtitle">
      Podprite svoje najljubše ustvarjalce preko NFT-jev<br />
      ali pa ponudite svoje digitalne umetnine!
    </p>
    <router-link to="/form"  class="btnPrimary">
      <span class="join">Naroči se na seznam ➔</span>
    </router-link>
    <!--<a href="#" class="btnSecondary">
      <span class="join">See our whitepaper</span>
    </a>-->
    <div class="cards">
      <Card
        v-for="(card, i) in cardTitles"
        :key="i"
        :imageId="i + 1"
        :title="card"
        :ethValue="ethValues[i]"
      />
    </div>
    <div class="rightElements">
      <img
        class="right-img"
        :src="`https://picsum.photos/500/700`"
        alt="image"
      />
      <div class="iphone-shadow-top"></div>
      <div class="iphone-shadow-bottom"></div>
      <Iphone />
    </div>
    <div id="join-the-presale" class="presale-block">
      <div class="center">
        <h1>Prihaja kmalu v Slovenijo</h1>
        <p>
          Sodelujte pri razvoju in se naročite na seznam uporabnikov, ki bodo
          imeli prvi dostop do tržnice tako da izpolnite spodnji obrazec ali pa
          nam pišite na info@nftart.si v kolikor želite med prvimi prodati vaša
          umetniška dela.
        </p>
      </div>
      <div class="presale-shadow-top"></div>
      <div class="presale-shadow-bottom"></div>
    <div id="obrazec" class="presale-countdown">
      <Subscribe
        :element-id="'first-email-signup-form'"
        :url="'https://gmail.us5.list-manage.com/subscribe/post?u=2c2c91cb68c6189dd71dd5572&id=eb92621c88'"
        :title="'Naroči se na obvestila'"
        :subtitle="'Zasebnost jemljemo resno in vaših podatkov ne bomo dajali drugim ali prodajali.'"
      />
    </div>
    </div>
  </main>
</template>

<script>
import Card from "./Card.vue";
import Iphone from "./Iphone.vue";
import Subscribe from "./Subscribe.vue";

export default {
  data() {
    return {
      cardTitles: ["Digitalna umetnost", "Glasba", "Zbirateljski predmeti"],
      ethValues: [1.0, 2.1, 0.6],
    };
  },
  components: {
    Card,
    Iphone,
    Subscribe,
  },
};
</script>

<style lang="scss">
@import "../assets/_variables.scss";
@import "../assets/_button.scss";
@import "../assets/_card.scss";

main {
  width: 75%;
  margin: auto;
  margin-top: 4rem;
  position: relative;

  .chip {
    width: max-content;
    color: #af60ba;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    background-color: #f5effc;
    font-weight: bold;
  }

  .coloredTitle {
    margin-top: 2rem;
    width: max-content;
    font-size: 40px;
    color: black;
    background: linear-gradient(to right, $primary, $secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .coloredSubtitle {
    color: $secondary;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1.25rem;
    margin-right: 1.5rem;
  }

  .rightElements {
    position: absolute;
    top: 0;
    right: 5rem;

    img.right-img {
      position: absolute;
      z-index: 0;
      right: 10rem;
      border-radius: 10px 60px 60px 10px;
    }

    .iphone-shadow-top {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 10rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($primary, 0.7);
    }
    .iphone-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 35rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.6);
    }
  }

  .center {
    text-align: center;
    p {
      font-size: 1.25rem;
    }
  }

  .presale-block {
    position: relative;
    z-index: 1;

    .presale-shadow-top {
      width: 290px;
      height: 285px;
      position: absolute;
      right: 32%;
      top: 15rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.5);
    }
    .presale-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      left: 32%;
      bottom: 5rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba(0, 150, 250, 0.7);
    }
  }
}
</style>