<template>
  <main>
    <h1>Za investitorje</h1>
    <p>
      NFTART.si je spletna tržnica NFT-jev v nastajanju. Uporabnikom bo
      omogočala nakup in dražbo različnih digitalnih umetnosti (glasbo,
      fotografije, risbe, videoposnetki, ipd.). Uporablja se za ustvarjanje,
      prodajo ali zbiranje digitalnih predmetov, zavarovanih s tehnologijo
      veriženja blokov. Naša tržnica bo uporabnikom ponudila trgovanje z
      intelektualnimi lastninami in vsemi digitalnimi vsebinami z licenciranjem
      preko žetonov NFT (ang. non fungible tokens), ki omogoča ustvarjanje in
      prodajanje digitalnih zbirateljskih predmetov.
    </p>
    <h2>Kaj ponuja »lastništvo« NFT?</h2>
    <p>
      Ko potrošnik kupi nezamenljivi kriptožeton (ang. non fungible tokens) na
      tržnici s tem pridobi preverljiv digitalni žeton, ki predstavlja kupčevo
      lastništvo sredstva v tej verigi blokov. "Nezamenljivi" del imena pomeni,
      da so žetoni edinstveni in jih ni mogoče zamenjati, kar jih loči od
      zamenljivih "žetonov", kot so kriptovalute, ki se lahko uporabljajo kot
      sredstvo menjave. Vendar pa v primeru da kupec če kupi NFT, ki predstavlja
      delež določenega dela digitalne umetnosti, ni nujno, da s tem pridobi
      pravice intelektualne lastnine v sami umetnosti.
    </p>
    <h2>Kako se NFTART razlikuje od obstoječih NFT tržnic?</h2>
    <p>
      Obstoječe tržnice postajajo prenasičene z nizko kakovostnimi predmeti,
      duplikati in so slabše podprt z avdio-video vsebinami, to bomo rešili z
      drugačnim izbirnim pristopom ustvarjalcev, tako za izboljšanje varnosti
      kot tudi edinstvene ponudbe digitalnih predmetov. Tako bodo imeli
      slovenski ljubitelji digitalne umetnosti, vlagatelji, glasbeniki in NFT
      igralci iger boljšo vstopno točko v svet nezamenljivih žetonov.
    </p>
    <h2>Članki o vlagateljih v NFT tržnice</h2>
    <p>
      Na spodnjih povezavah si lahko preberete članke o vlagateljih v obstoječe
      NFT tržnice:
      <ul>
          <li><a href="https://www.ledgerinsights.com/mark-cuban-backed-blockchain-nft-marketplace-mintable-raises-13m/" target="_blank">Mark Cuban backed NFT marketplace Mintable raises $13m</a></li>
          <li><a href="https://www.ledgerinsights.com/nft-marketplace-opensea-raises-23-million-andreessen-horowitz/" target="_blank">NFT marketplace OpenSea raises $23 million led by Andreessen Horowitz</a></li>
          <li><a href="https://www.ledgerinsights.com/nft-marketplace-rarible-raises-14m-after-reaching-150m-sales/" target="_blank">NFT marketplace Rarible raises $14.2m after reaching $150m sales</a></li>
          <li><a href="https://www.ledgerinsights.com/nft-platform-mojito-raises-20-million-powers-sothebys-metaverse/" target="_blank">NFT platform Mojito raises $20 million, powers Sotheby’s metaverse</a></li>
      </ul>
    </p>
    <p>
        Pošljite nam email na <b>info@nftart.si</b> v kolikor se zanimate za investiranje v naš projekt. 
    </p>
  </main>
</template>

<script>
export default {
  name: "Investors",
};
</script>

<style lang="scss">
@import "../assets/_variables.scss";
@import "../assets/_button.scss";
@import "../assets/_card.scss";

main {
  width: 75%;
  margin: auto;
  margin-top: 4rem;
  position: relative;

  .chip {
    width: max-content;
    color: #af60ba;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    background-color: #f5effc;
    font-weight: bold;
  }

  .coloredTitle {
    margin-top: 2rem;
    width: max-content;
    font-size: 40px;
    color: black;
    background: linear-gradient(to right, $primary, $secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .coloredSubtitle {
    color: $secondary;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1.25rem;
    margin-right: 1.5rem;
  }

  .rightElements {
    position: absolute;
    top: 0;
    right: 5rem;

    img.right-img {
      position: absolute;
      z-index: 0;
      right: 10rem;
      border-radius: 10px 60px 60px 10px;
    }

    .iphone-shadow-top {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 10rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($primary, 0.7);
    }
    .iphone-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 35rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.6);
    }
  }

  .center {
    text-align: center;
    p {
      font-size: 1.25rem;
    }
  }

  .presale-block {
    position: relative;
    z-index: 1;

    .presale-shadow-top {
      width: 290px;
      height: 285px;
      position: absolute;
      right: 32%;
      top: 15rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.5);
    }
    .presale-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      left: 32%;
      bottom: 5rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba(0, 150, 250, 0.7);
    }
  }
}
</style>