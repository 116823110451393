import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Investors from '../views/Investors.vue'
import Artists from '../views/Artists.vue'
import Form from '../views/Form.vue'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/artists', name: 'Artists', component: Artists },
  { path: '/investors', name: 'Investors', component: Investors },
  { path: '/form', name: 'Form', component: Form },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

export default router