<template>
  <main>
    <h1>Za umetnike</h1>
    <p>
      Tehnologija veriženja blokov je pripeljala ne-zamenljive žetone (NFT). Ustvarjanje in nakup digitalnih sredstev je danes trend. Nezamenljivi žetoni so enote podatkov, 
      ki so shranjene na verigi blokov. Lahko so v obliki zvoka, slik, videoposnetkov, itd. Čeprav lahko digitalno datoteko kopirajo ali shranijo številni, ima le lastnik dostop do izvirne datoteke in njenih pravic. 
    </p>
    <p>
      NFTART.si je spletna tržnica NFT-jev v nastajanju. Uporabnikom bo
      omogočala nakup in dražbo različnih digitalnih umetnosti (glasbo,
      fotografije, risbe, videoposnetki, ipd.). Uporablja se za ustvarjanje,
      prodajo ali zbiranje digitalnih predmetov, zavarovanih s tehnologijo
      veriženja blokov. Naša tržnica bo uporabnikom ponudila trgovanje z
      intelektualnimi lastninami in vsemi digitalnimi vsebinami z licenciranjem
      preko žetonov NFT (ang. non fungible tokens), ki omogoča ustvarjanje in
      prodajanje digitalnih zbirateljskih predmetov.
    </p>
    <h2>Kaj ponuja »lastništvo« NFT?</h2>
    <p>
      Ko potrošnik kupi nezamenljivi kriptožeton (ang. non fungible tokens) na
      tržnici s tem pridobi preverljiv digitalni žeton, ki predstavlja kupčevo
      lastništvo sredstva v tej verigi blokov. "Nezamenljivi" del imena pomeni,
      da so žetoni edinstveni in jih ni mogoče zamenjati, kar jih loči od
      zamenljivih "žetonov", kot so kriptovalute, ki se lahko uporabljajo kot
      sredstvo menjave. Vendar pa v primeru da kupec če kupi NFT, ki predstavlja
      delež določenega dela digitalne umetnosti, ni nujno, da s tem pridobi
      pravice intelektualne lastnine v sami umetnosti.
    </p>
    <h2>Zakaj postati umetnik na NFTART.si?</h2>
    <p>
      Obstoječe tržnice postajajo prenasičene z nizko kakovostnimi predmeti,
      duplikati in so slabše podprt z avdio-video vsebinami, to bomo rešili z
      drugačnim izbirnim pristopom ustvarjalcev, tako za izboljšanje varnosti
      kot tudi edinstvene ponudbe digitalnih predmetov. Tako bodo imeli
      slovenski ljubitelji digitalne umetnosti, vlagatelji, glasbeniki in NFT
      igralci iger boljšo vstopno točko v svet nezamenljivih žetonov.
    </p>
    <p>
        Pošljite nam email na <b>info@nftart.si</b> v kolikor želite da vas uvrstimo na prednostno listo ustvarjalcev. 
    </p>
  </main>
</template>

<script>
export default {
  name: "Artists",
};
</script>

<style lang="scss">
@import "../assets/_variables.scss";
@import "../assets/_button.scss";
@import "../assets/_card.scss";

main {
  width: 75%;
  margin: auto;
  margin-top: 4rem;
  position: relative;

  .chip {
    width: max-content;
    color: #af60ba;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    background-color: #f5effc;
    font-weight: bold;
  }

  .coloredTitle {
    margin-top: 2rem;
    width: max-content;
    font-size: 40px;
    color: black;
    background: linear-gradient(to right, $primary, $secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .coloredSubtitle {
    color: $secondary;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1.25rem;
    margin-right: 1.5rem;
  }

  .rightElements {
    position: absolute;
    top: 0;
    right: 5rem;

    img.right-img {
      position: absolute;
      z-index: 0;
      right: 10rem;
      border-radius: 10px 60px 60px 10px;
    }

    .iphone-shadow-top {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 10rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($primary, 0.7);
    }
    .iphone-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 35rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.6);
    }
  }

  .center {
    text-align: center;
    p {
      font-size: 1.25rem;
    }
  }

  .presale-block {
    position: relative;
    z-index: 1;

    .presale-shadow-top {
      width: 290px;
      height: 285px;
      position: absolute;
      right: 32%;
      top: 15rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.5);
    }
    .presale-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      left: 32%;
      bottom: 5rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba(0, 150, 250, 0.7);
    }
  }
}
</style>