<template>
  <main>
    <div class="saleForm">
      <h1>Naroči se na obvestila</h1>
      <p>
        Zasebnost jemljemo resno in vaših podatkov ne bomo dajali drugim ali
        prodajali.
      </p>
      <Subscribe />
    </div>
  </main>
</template>

<script>
import Subscribe from "../components/Subscribe.vue";

export default {
  components: { Subscribe },
  name: "Form",
};
</script>

<style lang="scss">
@import "../assets/_variables.scss";
@import "../assets/_button.scss";
@import "../assets/_card.scss";

main {
  width: 75%;
  margin: auto;
  margin-top: 4rem;
  position: relative;

  .saleForm {
    display: block;
    text-align: center;
  }

  .subscribe-container {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .chip {
    width: max-content;
    color: #af60ba;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    background-color: #f5effc;
    font-weight: bold;
  }

  .coloredTitle {
    margin-top: 2rem;
    width: max-content;
    font-size: 40px;
    color: black;
    background: linear-gradient(to right, $primary, $secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .coloredSubtitle {
    color: $secondary;
    font-weight: bold;
  }

  .subtitle {
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .btnPrimary,
  .btnSecondary {
    font-size: 1.25rem;
    margin-right: 1.5rem;
  }

  .rightElements {
    position: absolute;
    top: 0;
    right: 5rem;

    img.right-img {
      position: absolute;
      z-index: 0;
      right: 10rem;
      border-radius: 10px 60px 60px 10px;
    }

    .iphone-shadow-top {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 10rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($primary, 0.7);
    }
    .iphone-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      right: 1rem;
      top: 35rem;
      z-index: -1;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.6);
    }
  }

  .center {
    text-align: center;
    p {
      font-size: 1.25rem;
    }
  }

  .presale-block {
    position: relative;
    z-index: 1;

    .presale-shadow-top {
      width: 290px;
      height: 285px;
      position: absolute;
      right: 32%;
      top: 15rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba($secondary, 0.5);
    }
    .presale-shadow-bottom {
      width: 290px;
      height: 185px;
      position: absolute;
      left: 32%;
      bottom: 5rem;
      z-index: 0;
      border-radius: 45px;
      box-shadow: 0 0px 455px 0px rgba(0, 150, 250, 0.7);
    }
  }
}
</style>